/* eslint-disable */
import React, { useState } from 'react';
import cs from 'classnames';
import merge from 'lodash/merge';
import { get, omit } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { LinearProgress } from '@material-ui/core';

import { selectProductCode } from 'store/product';
import { reselectLocaleCode } from 'store/localize';
import { Checkbox, Form, Sidebar } from 'components';
import { CONFIG } from 'shared/constants/config.consts';
import {
  accountDetailsStoreEnhancer,
  userFormEnhancer,
  formGroups,
  useRenderInput,
  FormField,
  InjectedFormikUserFormProps,
  mipFormGroups,
  addInfoFormGroups
} from './UserForm.helpers';
import styles from './UserForm.module.css';
import { SubmissionPlanToggler } from '../../SubmissionPlanToggler/SubmissionPlanToggler';
import DownloadToCloud from '../../../../../../assets/images/DownloadToCloud.svg';
import XLSIcon from '../../../../../../assets/images/xlsIcon.svg';
import Close from '../../../../../../assets/images/Close.svg';
import Retry from '../../../../../../assets/images/Retry.svg';
import Tick from '../../../../../../assets/images/Succes.svg';
import { apiService } from 'shared/services';
import { constants } from 'os';

const UserFormCompo: React.FC<InjectedFormikUserFormProps> = ({
  displayId,
  isMultipleUser,
  formErrors,
  isValid,
  values,
  setFieldError,
  onSubmit,
  onCancel,
  productRoles,
  organization
}) => {
  enum FILE_STATUSES {
    SELECTED = 'Selected',
    LOADING = 'Uploading',
    UPLOADED = 'Uploaded',
    FAILED = 'Failed'
  }
  const productCode = useSelector(selectProductCode);
  const localeCode = useSelector(reselectLocaleCode);
  const [isConcentChecked, setConcentFlag] = useState(false);
  const [file, setFile] = useState<File>(null);
  const [uploadStarted, setUploadStarted] = useState(false);
  const [FileUploadErrorText, setFileUploadErrorText] = useState('');
  const [underLineLoader, setunderLineLoader] = useState('');
  const [FileUploadError, setFileUploadError] = useState(false);
  const [hasUploaded, setHasUploaded] = useState(false);
  const [uploadPercentage, setUploadPercentage] = useState(0);
  const [statusAdd, setStatusAdd] = useState<string>(FILE_STATUSES.SELECTED);
  const [taskId, setTaskId] = useState<string>('0');
  let enableAddUsers =
    isMultipleUser && statusAdd === FILE_STATUSES.UPLOADED && isConcentChecked;
  const barDesign =
    statusAdd === FILE_STATUSES.LOADING
      ? styles.barUploading
      : statusAdd === FILE_STATUSES.FAILED
      ? styles.barFailed
      : statusAdd === FILE_STATUSES.SELECTED
      ? styles.barSelected
      : styles.barLoaded;
  const handleSubmit = React.useCallback((): void => {
    onSubmit(
      merge(
        {},
        {
          UserProductRole: get(values, 'Role.productRoleId'),
          ...omit(values, 'Role'),
          isConcentChecked
        }
      )
    );
  }, [onSubmit, values, isConcentChecked]);

  const { t } = useTranslation();

  const renderInput = useRenderInput({
    Role: productRoles
  });

  const renderStateInput = useRenderInput({
    'organization.state': organization.state.code
  });

  const mapFormFields = React.useCallback(
    (row: FormField[], index: number) => (
      <Form.Row key={index.toString()}> {row.map(renderInput)} </Form.Row>
    ),
    [renderInput]
  );
  const mapAddInfoFormFields = React.useCallback(
    (row: FormField[], index: number) => {
      return (
        <Form.Row key={index.toString()}>
          {' '}
          {row.map(renderStateInput)}{' '}
        </Form.Row>
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [renderInput]
  );

  const mapFormGroups = React.useCallback(
    ({ fields, title }, index: number): JSX.Element => (
      <Form.Group key={`${index}userformgroup`} title={title}>
        {fields.map(mapFormFields)}
      </Form.Group>
    ),
    [mapFormFields]
  );

  const mapAddInfoFormGroups = React.useCallback(
    ({ fields, title }, index: number): JSX.Element => (
      <Form.Group key={`${index}addInfoFormgroup`} title={title}>
        {fields.map(mapAddInfoFormFields)}
      </Form.Group>
    ),
    [mapAddInfoFormFields]
  );

  const form = React.useMemo(
    (): JSX.Element[] =>
      productCode === 'mip'
        ? mipFormGroups.map(mapFormGroups)
        : formGroups.map(mapFormGroups),
    [mapFormGroups, productCode]
  );
  const additionalInformationForm = React.useMemo(
    (): JSX.Element[] => addInfoFormGroups.map(mapAddInfoFormGroups),
    [mapAddInfoFormGroups]
  );

  React.useEffect((): void => {
    if (formErrors) {
      Object.keys(formErrors).forEach((key): void => {
        setFieldError(key, get(formErrors, key));
      });
    }
  }, [formErrors, setFieldError]);

  const handleChecked = () => {
    setConcentFlag(!isConcentChecked);
  };

  const allFieldArevalid =
    values.UserEmail.trim() &&
    /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>().,;\s@"]+\.{0,1})+[^<>().,;:\s@"]{2,})$/.test(
      values.UserEmail.trim()
    ) &&
    values.UserEmail.toLocaleLowerCase().trim().indexOf('deloitte') === -1 &&
    values.FirstName.trim() &&
    values.LastName.trim() &&
    isConcentChecked &&
    isValid;

  const isFormValid =
    productCode !== 'mip'
      ? allFieldArevalid
      : allFieldArevalid && get(values, 'Role.productRoleId');

  const [
    isAdditionalInformationContainerOpen,
    toggleAdditionalInformationContainer
  ] = React.useState<boolean>(false);

  interface FileData {
    file: File;
    error: string;
    status: FILE_STATUSES;
  }

  const [isFileOver, setIsFileOver] = useState(false);
  const isDisabled = true;
  const templatePath = `/user-template/ ${localeCode} /`;

  const handleDownloadTemplate = () => {
    const url = `/user-template/${localeCode}/${CONFIG.USR_TEMPLATE}`;
    const templateName = CONFIG.USR_TEMPLATE;
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = url;
    a.download = templateName;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };
  const handleDragOver = (e: React.DragEvent) => {
    e.stopPropagation();
    e.preventDefault();
    setIsFileOver(true);
  };

  const handleDropFile = (e: React.DragEvent) => {
    e.stopPropagation();
    e.preventDefault();
    setIsFileOver(false);
    setUploadStarted(true);
    handleSetFiles(e.dataTransfer.files[0]);
  };

  const handleDragLeave = (e: React.DragEvent) => {
    e.stopPropagation();
    e.preventDefault();
    setIsFileOver(false);
  };

  const handleChooseFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleSetFiles(e.target.files[0]);

    e.target.value = '';
  };

  const handleSetFiles = (addUsersList: File) => {
    setFileUploadError(false);
    if(statusAdd != FILE_STATUSES.FAILED)
      setFileUploadErrorText('');
    setUploadStarted(true);
    
    if (addUsersList != null) {
      setFile(addUsersList);
      const errorMessage = getErrorMessage(addUsersList);
      if (errorMessage === '') startUploading(addUsersList);
      else setStatusAdd(FILE_STATUSES.FAILED);
    }
  };
  const handleRetry = () => {
    setFile(null);
    setFileUploadErrorText('');
    setUploadStarted(false);
  };
  const handleCancelBtn = () => {
    setUploadPercentage(0);
    setFileUploadErrorText(t('admin.adduser.fileUploadFailed'));
    apiService
      .deleteAdditionalUserExcel(taskId)
      .then((res: any) => {
        setStatusAdd(FILE_STATUSES.FAILED);
        setFileUploadError(true);
        setUploadPercentage(0);
        setFileUploadErrorText(t('admin.adduser.fileUploadFailed'));
      })
      .catch((err) => {
        setStatusAdd(FILE_STATUSES.FAILED);
        setFileUploadError(true);
        if(progressBarStatus != FILE_STATUSES.UPLOADED){
          setFileUploadErrorText(t('admin.adduser.somethingWentWrong'));
        }
      });
  };
  const handleAddUsers = () => {
    enableAddUsers = false;
    apiService
      .addingBulkAdditionalUsers(taskId)
      .then((res: any) => {
        onCancel();
      })
      .catch((err) => {});
  };
  let [progress, setProgress] = React.useState(0);

  let progressBarStatus = statusAdd;
  let hProgress = 0;
  const handleStatus = () => {
    apiService
      .getStatusForBulkAdditionalUsers(taskId)
      .then((res: any) => {
        setProgress(res.statusPercentage);
        hProgress = res.statusPercentage;
        if (res.status === FILE_STATUSES.FAILED) {
          setUploadPercentage(0);
          setStatusAdd(FILE_STATUSES.FAILED);
          setFileUploadError(true);
          if(res.errorDescription!= null)
            setFileUploadErrorText(res.errorDescription);
          hProgress = 0;
        } else if (res.status === FILE_STATUSES.UPLOADED) {
          setUploadPercentage(0);
          setStatusAdd(FILE_STATUSES.UPLOADED);
        }
        progressBarStatus = res.status;
      })
      .catch((err) => {  });
  };

  React.useEffect(() => {
    const timer = setInterval(() => {
      handleStatus();
      if(progressBarStatus === FILE_STATUSES.UPLOADED){
        setFileUploadErrorText('');
      }
      if (progressBarStatus != FILE_STATUSES.LOADING) {
        setProgress(0);
        setUploadPercentage(0);
        clearInterval(timer);
      }
    }, 1500);
  }, [taskId]);

  const startUploading = async (curfile: File) => {
    const data = new FormData();
    data.append('file', curfile, curfile.name);
    data.append('AccountId', displayId);
    data.append('ProductCode', productCode);
    data.append('LocaleCode', localeCode);
    data.append('organization', organization.legalName);
    data.append('UserProductRole', '2');
    data.append('IsConcentChecked', 'true');

    setStatusAdd(FILE_STATUSES.SELECTED);
    setUploadPercentage(50); 
    apiService
      .uploadAdditionUsers(data)
      .then((res: any) => {
        setStatusAdd(FILE_STATUSES.LOADING);
        setTaskId(res.data);
      })
      .catch((err) => {
        setUploadPercentage(0);
        setStatusAdd(FILE_STATUSES.FAILED);
        setUploadPercentage(0); 
        setFileUploadError(true);
        setFileUploadErrorText(t('admin.adduser.somethingWentWrong'));
      });
  };
  const getErrorMessage = (file: File): string => {
    let errorMessage = '';
    const XLSX =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    const MAX_FILE_SIZE = 10485760;

    if (file.type !== XLSX) {
      errorMessage = t('admin.adduser.unSupportedFile');
      setFileUploadError(true);
      setFileUploadErrorText(errorMessage);
    }

    if (file.size > MAX_FILE_SIZE) {
      errorMessage = t('admin.adduser.maxFileSizeError');
      setFileUploadError(true);
      setFileUploadErrorText(errorMessage);
    }

    return errorMessage;
  };
  return (
    <React.Fragment>
      <div className={styles.detailsSection}>
        {isMultipleUser !== true && (
          <div className={styles.content}>
            <Form>{form}</Form>
          </div>
        )}

        {isMultipleUser === true && (
          <div>
            {uploadStarted === true && (
              <ul className={styles.uploadedfiles}>
                <li
                  key={file.lastModified}
                  className={styles.uploadedfilesitem}
                >
                  <div className={styles.uploadedfilesitemwrap}>
                    <div className={styles.uploadedfilesitemcontent}>
                      <div className={styles.excelFileStatus}>
                        <div className={styles.arrangeItems}>
                          <img alt='Excel' className={styles.xlsimage} src={XLSIcon} />
                          <span> {file.name} </span>
                          <span> {statusAdd}</span>
                        </div>
                        <span className={styles.closeStatus}>
                          {statusAdd === FILE_STATUSES.LOADING && (
                            <button
                              className={styles.uploadedfilesdelete}
                              onClick={() => {
                                file && handleCancelBtn();
                              }}
                            >
                              <img alt='Delete' src={Close} />
                            </button>
                          )}

                          {statusAdd === FILE_STATUSES.FAILED && (
                            <button
                              className={styles.uploadedfilesdelete}
                              onClick={handleRetry}
                            >
                              <img alt='Retry' src={Retry} />
                            </button>
                          )}

                          {statusAdd === FILE_STATUSES.UPLOADED && (
                            <button className={styles.uploadedfilesdelete}>
                              <img alt='Success' src={Tick} />
                            </button>
                          )}
                        </span>
                      </div>
                    </div>

                    <div className={styles.progressWrap}>
                      <LinearProgress
                        className={barDesign}
                        value={uploadPercentage}
                        variant='determinate'
                      />
                    </div>

                    <div className={styles.uploadedfileserror}>
                      {FileUploadErrorText}
                    </div>
                  </div>
                </li>
              </ul>
            )}

            {uploadStarted === false && (
              <div className={styles.outdrop}>
                <div className={styles.dropzone}
                        onDragLeave={handleDragLeave}
                        onDragOver={handleDragOver}
                        onDrop={handleDropFile}>
                  <div>
                    {file === null && (
                      <div
                        className={cs('drop-file-area', {
                          'drop-file-area-focus': isFileOver && !isDisabled,
                          'drop-file-area-disabled': isDisabled
                        })}
                      >
                        <div>
                          <img alt='Download' src={DownloadToCloud} />
                        </div>
                        <div className={styles.filebrowsetext} id='samePage'>
                          <span className={styles.spndragdrop}>
                            {t('admin.adduser.dragandDrop')}
                            <label
                              className={styles.filebrowse}
                              htmlFor='file-browse'
                            >
                              <input
                                accept='.xlsx'
                                className='browsefile'
                                disabled={false}
                                id='file-browse'
                                multiple={false}
                                type='file'
                                onChange={handleChooseFile}
                              />
                              {t('admin.adduser.browse')}
                            </label>
                          </span>
                          <p>{t('admin.adduser.maxFileSize')}</p>
                        </div>
                        <button
                          className={styles.templateButton}
                          onClick={handleDownloadTemplate}
                        >
                          {t('admin.adduser.downloadtemplate')}
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        )}

        <div className={styles.concentContainer}>
          <div className={styles.additionalInfoContainer}>
            <div className={cs(styles.concentItems, styles.headerText)}>
              {t('forms.additionalInformationSection.header')}
            </div>
            <div className={styles.toggleIcon}>
              <SubmissionPlanToggler
                isOpen={isAdditionalInformationContainerOpen}
                onToggle={React.useCallback((): void => {
                  toggleAdditionalInformationContainer(
                    (prevOpenState: boolean) => !prevOpenState
                  );
                }, [])}
              />
            </div>
          </div>
          {isAdditionalInformationContainerOpen && (
            <div className={styles.addInfoFormData}>
              <Form>{additionalInformationForm}</Form>
            </div>
          )}
          <div className={styles.concentItems}>
            <Checkbox
              checked={isConcentChecked}
              disabled={enableAddUsers}
              label={isMultipleUser?t('forms.additionalInformationSection.checkBoxTitleMultipleUsers'):t('forms.additionalInformationSection.checkBoxTitle')}
              name='concent'
              onChange={handleChecked}
            />
          </div>
        </div>
      </div>
      <Sidebar.Footer>
        <div className={styles.footer}>
          <div>
            <button
              className={styles.cancel_btn}
              type='button'
              onClick={onCancel}
            >
              {t('admin.adduser.cancel')}
            </button>
          </div>
          {isMultipleUser !== true && (
            <div>
              <button
                className='btn btn-primary'
                disabled={!isFormValid}
                type='button'
                onClick={handleSubmit}
              >
                {t('admin.adduser.add')}
              </button>
            </div>
          )}

          {isMultipleUser === true && (
            <div>
              <button
                className='btn btn-primary'
                disabled={!enableAddUsers}
                type='button'
                onClick={handleAddUsers}
              >
                {t('admin.adduser.addUsers')}
              </button>
            </div>
          )}
        </div>
      </Sidebar.Footer>
    </React.Fragment>
  );
};

export const UserForm = accountDetailsStoreEnhancer(
  userFormEnhancer(UserFormCompo)
);
