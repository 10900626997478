import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

import { errorException, traceAppInsights } from 'shared/utils/AppInsights';
import { LOCAL_STORAGE_TOKEN_KEY, TELEMETRY_LOGOUT_MESSAGE } from '../../shared/constants/common.consts';
import { useOnMount } from '../../shared/hooks';
import {
  calculateEndTime,
  getDifferenceInSecondsFromNow,
  getFromLocalStorage,
  hasTimeExpired,
  removeFromLocalStorage,
  setToLocalStorage
} from '../../shared/utils/storage.utils';
import { useTimeInterval } from '../../shared/hooks/useTimeInterval';
import { useEventsTracking } from '../../shared/hooks/useEventsTracking';
import {
  KEEP_ALIVE_TIMEOUT,
  LOGOUT_TIMEOUT,
  POPUP_TIMEOUT,
  SESSION_EXPIRES_AT,
  USER_ACTIVITY
} from '../../shared/constants/autologout.const';
import { Icon } from '../Icon/Icon';
import { apiService } from '../../shared/services';
import { setAccessToken, signOut } from '../../store/auth';
import styles from './LogoutWarningPopup.module.css';
import { getCookie } from '../../shared/utils';

interface Props {
  originalPopupTimeout: number;
  originalLogoutTimeout: number;
  originalKeepAliveTimeout: number;
}

export const LogoutWarningPopup: React.FC<Props> = ({
  originalLogoutTimeout,
  originalPopupTimeout,
  originalKeepAliveTimeout
}) => {
  const [isOpened, setIsOpened] = useState(false);
  const { t } = useTranslation();

  const [popupTimeout, setPopupTimeout] = useState(originalPopupTimeout);

  const logoutActionCallback = () => {
    const storageLogoutTimeout = getFromLocalStorage<string>(LOGOUT_TIMEOUT);
    const storageKeepAliveTimeout = getFromLocalStorage<string>(
      KEEP_ALIVE_TIMEOUT
    );
    const storagePopupTimeout = getFromLocalStorage<string>(POPUP_TIMEOUT);
    const storageSessionExpirationTime = getFromLocalStorage(
      SESSION_EXPIRES_AT
    );
    const storageUserActivity = getFromLocalStorage<boolean>(USER_ACTIVITY);
    if (!isOpened) {
      logoutOnSessionExpiration();

      if (
        (storageLogoutTimeout && hasTimeExpired(storageLogoutTimeout)) ||
        storagePopupTimeout
      ) {
        handleOpenPopup();
      } else if (
        !storageLogoutTimeout ||
        !storageKeepAliveTimeout ||
        !storageSessionExpirationTime
      ) {
        initTimeouts();
      }

      if (storageKeepAliveTimeout && hasTimeExpired(storageKeepAliveTimeout)) {
        if (storageUserActivity) {
          handleContinueSession();
          setTimeout(() => removeFromLocalStorage(USER_ACTIVITY), 500);
        } else {
          setToLocalStorage(
            KEEP_ALIVE_TIMEOUT,
            calculateEndTime(originalKeepAliveTimeout)
          );
        }
      }
    }
  };

  const popupActionCallback = () => {
    const storagePopupTimeout = getFromLocalStorage<string>(POPUP_TIMEOUT);
    logoutOnSessionExpiration();

    if (isOpened) {
      if (popupTimeout !== 0) {
        if (storagePopupTimeout) {
          setPopupTimeout(prevState => prevState - 1);
        } else {
          setIsOpened(false);
          stopPopupInterval();
          setPopupTimeout(originalPopupTimeout);
          startCheckingInterval();
        }
      } else {
        setToLocalStorage(SESSION_EXPIRES_AT, calculateEndTime());
        logoutInTimeout();
      }
    }
  };

  const {
    stopInterval: stopCheckingInterval,
    startInterval: startCheckingInterval
  } = useTimeInterval({
    actionCallback: logoutActionCallback,
    timeout: 500
  });

  const {
    stopInterval: stopPopupInterval,
    startInterval: startPopupInterval
  } = useTimeInterval({
    actionCallback: popupActionCallback
  });

  useOnMount(() => {
    const storageLogoutTimeout = getFromLocalStorage<string>(LOGOUT_TIMEOUT);
    const storageKeepAliveTimeout = getFromLocalStorage<string>(
      KEEP_ALIVE_TIMEOUT
    );
    const storagePopupTimeout = getFromLocalStorage<string>(POPUP_TIMEOUT);
    const storageSessionExpirationTime = getFromLocalStorage<string>(
      SESSION_EXPIRES_AT
    );

    logoutOnSessionExpiration();

    if (storagePopupTimeout) {
      handleOpenPopup();
    } else {
      if (
        !storageLogoutTimeout &&
        !storageKeepAliveTimeout &&
        !storageSessionExpirationTime
      ) {
        initTimeouts();
      }
      startCheckingInterval();
      const token = getCookie(LOCAL_STORAGE_TOKEN_KEY);

      if (token) {
        silentRenew();
      }
    }
  });

  const logoutInTimeout = () => {
    setTimeout(() => {
      signOut();
      traceAppInsights(`${TELEMETRY_LOGOUT_MESSAGE}`, 1, true);
    }, 2000);
  };

  const logoutOnSessionExpiration = () => {
    const storageSessionExpirationTime = getFromLocalStorage<string>(
      SESSION_EXPIRES_AT
      );
    if (
      storageSessionExpirationTime &&
      hasTimeExpired(storageSessionExpirationTime)
    ) {
      logoutInTimeout();
    }
  };

  const initTimeouts = useCallback(() => {
    setToLocalStorage(LOGOUT_TIMEOUT, calculateEndTime(originalLogoutTimeout));

    setToLocalStorage(
      KEEP_ALIVE_TIMEOUT,
      calculateEndTime(originalKeepAliveTimeout)
    );

    setToLocalStorage(
      SESSION_EXPIRES_AT,
      calculateEndTime(originalLogoutTimeout + originalPopupTimeout)
    );
  }, [originalKeepAliveTimeout, originalLogoutTimeout, originalPopupTimeout]);

  const silentRenew = useCallback(async () => {
    try {
      const response = await apiService.keepAlive();
      setAccessToken(response.accessToken);
    } catch (error) {
      // AppInsights Error Exception
      errorException('Error reques .../auth/keepalive, File reference: LogoutWarningPopup.tsx in silentRenew function');
      
      throw error;
    } finally {
      initTimeouts();
      startCheckingInterval();
    }
  }, [initTimeouts, startCheckingInterval]);

  const stopAllIntervals = useCallback(() => {
    stopCheckingInterval();
    stopPopupInterval();
  }, [stopCheckingInterval, stopPopupInterval]);

  const handleContinueSession = useCallback(() => {
    removeFromLocalStorage(POPUP_TIMEOUT);
    stopAllIntervals();
    setPopupTimeout(originalPopupTimeout);
    setIsOpened(false);
    silentRenew();
  }, [originalPopupTimeout, setIsOpened, silentRenew, stopAllIntervals]);

  const handleOpenPopup = () => {
    const storagePopupTimeout = getFromLocalStorage<string>(POPUP_TIMEOUT);

    if (!storagePopupTimeout) {
      setToLocalStorage(POPUP_TIMEOUT, calculateEndTime(originalPopupTimeout));
    } else {
      setPopupTimeout(getDifferenceInSecondsFromNow(storagePopupTimeout));
    }

    removeFromLocalStorage(LOGOUT_TIMEOUT);
    removeFromLocalStorage(KEEP_ALIVE_TIMEOUT);
    stopCheckingInterval();
    setIsOpened(true);
    startPopupInterval();
  };

  const handleLogout = useCallback(() => {
    setToLocalStorage(SESSION_EXPIRES_AT, calculateEndTime());
    logoutInTimeout();
  }, []);

  const handleEvents = useCallback(() => {
    const storageUserActivity = getFromLocalStorage<boolean>(USER_ACTIVITY);

    if (!storageUserActivity && !isOpened) {
      setToLocalStorage(USER_ACTIVITY, true);
    }
  }, [isOpened]);

  useEventsTracking(handleEvents, [isOpened]);

  return (
    <Modal
      backdrop='static'
      centered={true}
      show={isOpened}
      onHide={handleContinueSession}
    >
      <Modal.Header className={styles.header}>
        <Modal.Title className={styles.title}>
          {t('actions.logOutWarning')}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className={styles.body}>
        <div className={styles.iconCircle}>
          <Icon
            className={styles.icon}
            name='exclamation-mark-special'
            size={52}
          />
        </div>
        <div className={styles.text}>
          <p className={styles.message}>
            <h3>{t('actions.logOutWarningMsg', { seconds: popupTimeout })}</h3>
          </p>
          <p className={styles.note}>{t('actions.logOutWarningNote')}</p>
        </div>
      </Modal.Body>
      <Modal.Footer className={styles.footer}>
        <Button
          className={styles.yesaction}
          size='sm'
          variant='outline-secondary'
          onClick={handleLogout}
        >
          {t('forms.yes')}
        </Button>
        <Button
          className={styles.action}
          size='sm'
          variant='primary'
          onClick={handleContinueSession}
        >
          {t('actions.no')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
