import { ApplicationInsights, ITelemetryItem, ITelemetryPlugin, SeverityLevel } from "@microsoft/applicationinsights-web";
import { ReactPlugin, withAITracking } from "@microsoft/applicationinsights-react-js";
import { globalHistory } from "@reach/router";

const reactPlugin = new ReactPlugin();
const instrumentationKey = (window as any).APP_CONFIG.APP_INSIGHTS_INSTRUMENTATION_KEY;

const ai = new ApplicationInsights({
    config: {
        instrumentationKey,
        extensions: [reactPlugin],
        extensionConfig: {
            [reactPlugin.identifier]: { history: globalHistory }
        }
    }
});

ai.loadAppInsights();

ai.addTelemetryInitializer((env: ITelemetryItem) => {
    const newEnv = { ...env };
    newEnv.tags = newEnv.tags || [];
    newEnv.tags["ai.cloud.role"] = "GASS-Admin-UI";
    Object.assign(env, newEnv);
});

export const { appInsights } = ai;
export { reactPlugin };

// LOGS FUNCTIONS
export const errorException = (message: string): void => {
    appInsights.trackException({ error: new Error(message), severityLevel: SeverityLevel.Error });
};

export const traceAppInsights = (message: string, severity: number, isTimeStamp = false): void => {
  let newMessage;

  if (isTimeStamp) {
    const timestamp = Date.now();
    newMessage = `${message} Timestamp: ${timestamp}`;
  } else {
    newMessage = message;
  }

  appInsights.trackTrace({ message: newMessage, severityLevel: severity });
};